<template>
  <v-container fluid>
    <v-card flat :height="headerCardHeight">
      <v-row no-gutters class="fill-height">
        <v-col cols="3" align-self="center">
          <v-card-title class="text-h4">
            <v-icon large left color="primary lighten-3">
              mdi-google-analytics
            </v-icon>
            Kiosk Analytics

            <v-chip color="primary" class="text-h6 mt-3">
              {{ getKioskName }}
            </v-chip>
          </v-card-title>
        </v-col>
        <v-col cols="9" align-self="center">
          <v-row no-gutters>
            <v-col cols="4">
              <v-card width="85%">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total User Sessions
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalUserSessions > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalUserSessions }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card width="85%" class="">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total Questions Asked
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalQuestionsAsked > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalQuestionsAsked }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4" align-self="bottom">
              <v-card width="95%" class="">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Overall Performance
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <!-- <span v-if="kioskAnalytics.totalQuestionsAsked > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalQuestionsAsked }}
                  </span> -->
                  <span class="text-h5 font-weight-bold grey--text text--darken-2">
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="px-3 mt-3" flat>
      <v-tabs v-model="tab">
        <v-tab>
          <v-icon left>
            mdi-google-analytics
          </v-icon>
          Graphs</v-tab>
        <v-tab>
          <v-icon left>
            mdi-timer-check
          </v-icon>
          User Sessions</v-tab>
        <v-tab>
          <v-icon left>
            mdi-forum
          </v-icon>
          Q&A Analytics</v-tab>
        <v-spacer></v-spacer>
        <v-card flat width="200">
          <v-select :items="duration" outlined v-model="selectedDuration" dense class="mt-2"></v-select>
        </v-card>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <reports :userSessionList="userSessionList" :selectedDuration="selectedDuration"></reports>
        </v-tab-item>
        <v-tab-item>
          <userSession :loading="loading" :userSessionList="userSessionList">
          </userSession>
        </v-tab-item>
        <v-tab-item>
          <qnaAnalytics :analysisList="analysisList" :loading="loading">
          </qnaAnalytics>
        </v-tab-item>

        <!-- <v-tab-item>
                <reports></reports>                
              </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userSession from "@/components/dashboard/admin/kioskAnalytics/userSession.vue";
import qnaAnalytics from "@/components/dashboard/admin/kioskAnalytics/qnaAnalytics.vue";
import Reports from '../../components/dashboard/admin/kioskAnalytics/reports.vue';

export default {
  name: "kioskAnalytics",
  components: {
    userSession,
    qnaAnalytics,
    Reports
  },
  data() {
    return {
      selectedKiosk: null,
      tab: null,
      loading: false,
      headerCardHeight: window.innerHeight / 5.5,
      duration: ['Daily', 'Weekly', 'Annually'],
      selectedDuration: 'Daily'
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredKiosks",
      "kioskAnalytics",
      "getWindowDimensions",
      "selectKioskAnalytics"
    ]),
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    getKioskName() {
      let temp;
      console.log('Selected Kiosk: ', this.allRegisteredKiosks.filter(kiosk => kiosk.id === this.selectKioskAnalytics))
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.id === this.selectKioskAnalytics)
      return temp[0].data().name
    },
    analysisList() {
      let interactions = [];
      this.kioskAnalytics.qnaAnalytics.forEach((event) => {
        try {
          if (event.result.answers.length > 0) {
            interactions.push({
              kioskId: event.kioskID,
              result: event.result,
              timeStamp: event.timeStamp,
              transcript: event.transcript,
              id: event.id,
            });
          }
        } catch (err) {
          // console.error(err.message);
        }
      });

      return interactions.filter(interaction => interaction.kioskId === this.selectKioskAnalytics);
    },
    userSessionList() {
      let sessions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        if (event.userSession.length > 2) {
          let startTime = moment(event.sessionStartTime);
          let endTime = moment(event.sessionEndTime);
          sessions.push({
            id: event.id,
            kioskId: event.kioskId,
            userSessionDate: event.sessionStartTime,
            userSessionDuration: endTime.diff(startTime),
            userSessionStartTime: event.sessionStartTime,
            userSessionEndTime: event.sessionEndTime,
            userSessionLogs: event.userSession,
          });
        }
      });
      return sessions.filter(session => session.kioskId === this.selectKioskAnalytics);
      //return sessions
    },
  },
  watch: {
    selectedKiosk(newVal) {
      this.loadData(newVal);
    },
    getWindowDimensions(newVal) {
      this.headerCardHeight = newVal.height / 5.5;
    },
    selectedDuration(newVal) {
      if (newVal === 'Daily') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Daily',
          start: null,
          end: null
        })
      } else if (newVal === 'Weekly') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Weekly',
          start: null,
          end: null
        })
      } else if (newVal === 'Annually') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Annually',
          start: null,
          end: null
        })
      } else {
        //Custom Date
      }
    }
  },
  methods: {
    loadData(kioskName) {
      this.loading = true;
      this.timeline = null;
      this.allRegisteredKiosks.forEach((kiosk) => {
        if (kiosk.data().name === kioskName) this.fetchAnalyticalData(kiosk.id);
      });
    },
    fetchAnalyticalData(kioskId) {
      this.$store.dispatch("getKioskAnalytics", kioskId).then(
        (response) => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
  },
  mounted() {
    if (this.allRegisteredKiosks.length === 0) {
      this.$store.dispatch("getAllRegisteredKiosks");
    }
  },
};
</script>

 