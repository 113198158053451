<template>
    <v-card :height="kioskAnalyticsDimension.userSessionheight" flat>
        <v-row class="fill-height">
            <v-col cols="5" align-self="center">
                <annual-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Annually'"></annual-bar-chart>
                <weekly-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Weekly'"></weekly-bar-chart>
                <daily-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Daily'"></daily-bar-chart>
            </v-col>
            <v-col cols="4" align-self="center" align="center">
                <pie-chart></pie-chart>
            </v-col>
            <v-col cols="3" align-self="center" align="center">
                <language-bar-chart></language-bar-chart>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import AnnualBarChart from '../../../graphs/annualBarChart.vue';
import WeeklyBarChart from '../../../graphs/weeklyBarChart.vue';
import DailyBarChart from '../../../graphs/dailyBarChart.vue';
import { mapGetters } from 'vuex'
import PieChart from '../../../graphs/pieChart.vue';
import LanguageBarChart from '../../../graphs/languageBarChart.vue';
export default {
    name: 'reports',
    props: {
        userSessionList: Array,
        selectedDuration: String,
    },
    computed: {
        ...mapGetters(['kioskAnalyticsDimension'])
    },
    components: {
        AnnualBarChart,
        WeeklyBarChart,
        DailyBarChart,
        PieChart,
        LanguageBarChart
    },
    data() {
        return {
            //
        }
    }

}
</script>
<style>
.cardBorderStyle {
    border-right: solid 1px #111111 !important;
    border-radius: 0px !important;
}
</style>
